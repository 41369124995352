<template>
    <div>
        <div class="single-service-area fadeInRightBig" data-wow-duration="1.30s">
            <div class="single-service-title">
                <h2>Select Services</h2>
            </div>
            <div class="row">

                <div v-for="(service,key) in services" :key="key" class="single-service col-md-4 mr-1">
                    <div class="form-check mt-4" >
                        <input class="form-check-input" type="checkbox" name="service[]" @change="changeCheck(service)" v-model="selected" :value="service.id" :id="'flexCheckDefault' + key">
                    </div>
                    <img :src="app_url+ '/front2/assets/images/trafic.png'" :alt="service.title">
                    <p>{{ service.title }}</p>
                    <span>${{ service.amount }}</span>
                </div>
            </div>

        </div>
        <div class="totals">
            <div class="totals-item">
                <label class="font-bold">Subtotal</label>
                <div class="totals-value" id="cart-subtotal">{{ total }}</div>
            </div>
            <div class="totals-item">
                <label>GST ( {{ tax_percentage }}%)</label>
                <div class="totals-value" id="cart-tax">{{ tax_amount }}</div>
            </div>
            <!--                    <div class="totals-item">
                                    <label>Tax (5%)</label>
                                    <div class="totals-value" id="cart-tax">3.60</div>
                                </div>
                                <div class="totals-item">
                                    <label>Shipping</label>
                                    <div class="totals-value" id="cart-shipping">15.00</div>
                                </div>-->
            <div class="totals-item totals-item-total">
                <label class="font-bold">Grand Total</label>
                <div class="totals-value" id="cart-total">{{ (total + tax_amount) }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 col-sm-12"></div>
            <div class="col-md-4 col-sm-12 justify-content-end">
                <label>Select Payment Method</label>
                <select class="form-control" required name="payment_method" v-model.number="payment_method">
                    <option :value="null">Select One</option>
                    <option v-for="(type,key5) in payment_methods" :value="type.id" :key="key5">{{ type.name}}</option>
                </select>
<!--                <div class="form-check" v-for="(type,key5) in payment_methods" :key="key5">
                    <label class="form-check-label" :for="'radio'+key5">
                        <input type="radio" class="form-check-input" :id="'radio'+key5" name="payment_method" :value="type.name" :checked="key5===0">{{ type.name}}
                    </label>
                </div>-->
                <span v-if="note">N.B. {{ note }}</span>
            </div>
        </div>
        <button type="submit" class="checkout mb-2" :disabled="!selected.length">Checkout</button>
    </div>
</template>
<script>
export default {
    name:'ServicePurchase',
    props:{
        app_url:{
            required:true,
        },
        services:{
            required:true
        },
        payment_methods:{
            required:true,
            type:Array,
        },
        tax:{
            required:true,
        },
    },
    data(){
        return{
            selected:[],
            total:0,
            payment_method:null,
            note:'',
            tax_percentage:this.tax ? this.tax.tax_percentage ?? 0:0,
            tax_amount:0,
        }
    },
    methods:{
        changeCheck(item){
            if (this.selected.includes(item.id)){
                this.total = this.total + item.amount;
            }else {
                this.total = this.total - item.amount;
            }
        }
    },
    watch:{
        payment_method(){
            if (this.payment_method != null){
                let ff = this.payment_methods.find(item=>item.id===this.payment_method);
                if (ff) this.note=ff.note;
            }else this.note='';
        },
        total(){
            this.tax_amount = (this.total * (this.tax_percentage / 100 ));
        }
    }
}
</script>
