<template>
    <div>
        <div class="messenger-box-icon">
            <p>Any Question ?</p>
            <a href="javascript:void(0)" @click="showHide()"><i class="fab fa-facebook-messenger"></i></a>
        </div>
        <div class="messenger-form" :style="'left:'+style+'%'" style="">
            <div class="banner-bottom">
                <form action="javascript:void(0)" method="POST">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-left">
                                <!-- Heading -->
                                <div class="form-heading">
                                    <p>Any Question ?</p>
                                    <div class="from-close">
                                        <span @click="closeAll()">X</span>
                                    </div>
                                </div>
                                <form action="javascript:void(0)" v-if="step===0" @submit="step=1">
                                    <div class="form-group">
                                        <label for="name">Name <span>*</span></label>
                                        <input class="your-name" name="name" v-model="name" type="text" placeholder="Name" required>
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email <span>*</span></label>
                                        <input class="your-name" v-model="email" name="email" type="email" placeholder="Email" required>
                                    </div>
                                    <button  type="submit" class="theme-btn">Start Here</button>
                                </form>
                                <form action="javascript:void(0)" v-else @submit="submitQuestion()">
                                    <div class="row column header">
                                        <Scroll-Div id="scroll_id" v-if="!parseInt(isResult)" class="message-area" width="400px" height="200px" view-class="searchResult">
                                            <div class="message-content-area" v-for="(qa,key) in q_answers" :key="key">
                                                <div class="message-content-heading">
                                                    <span>Q .</span>
                                                    <p>{{ qa.question }}</p>
                                                </div>
                                                <div class="message-description">
                                                    <span v-if="qa.type" style="color: #fe6c22de">- </span>
                                                    <span v-else >A. </span>
                                                    <div>
                                                        <span v-if="qa.type" style="color: #fe6c22de"><i>{{ qa.answer }}</i></span>
                                                        <span v-else>{{ qa.answer }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Scroll-Div>
                                        <Scroll-Div class="message-area" v-else-if="parseInt(isResult)" width="400px" height="200px" view-class="searchResult">
                                            <div class="searchResult" >
                                                <transition-group name="expand" tag="div" >
                                                    <a href="javascript:void(0)" v-for="(elem,key) in articleObj" :key="key">
                                                        <div class="medium-8 medium-offset-2 columns card" @click="resultClick(elem)">
                                                            <h1 class="text-headline">{{ elem.question }}</h1>
                                                            <p class="text-body-1">{{ elem.answer }}</p>
                                                        </div>
                                                    </a>
                                                </transition-group>
                                            </div>
                                        </Scroll-Div>
                                        <div class="medium-6 medium-offset-3">
                                            <form v-on:submit.prevent="submitSearch()">
                                                <!-- Using slots -->
                                                <b-input-group class="mt-3">
                                                    <template #append>
                                                        <b-input-group-text @click="submitQuestion()">
                                                            <strong class="text-danger"><i class="fa fa-paper-plane" aria-hidden="true"></i></strong>
                                                        </b-input-group-text>
                                                    </template>
                                                    <b-form-input name="question" v-model="searchQuery" placeholder="Type here" @keyup.enter.native="submitQuestion()" @keyup="submitSearch()"></b-form-input>
                                                </b-input-group>
                                            </form>
                                        </div>
                                        <button type="button" @click="closeAll()" class="theme-btn">Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { BInputGroup, BFormInput,BInputGroupText,  } from 'bootstrap-vue'
import Toaster from 'v-toaster'
import 'v-toaster/dist/v-toaster.css'
import Vue from "vue";
import ScrollDiv from 'vue-scroll-div';
Vue.use(ScrollDiv);

Vue.use(Toaster, {timeout: 5000})
import axios from "axios";
export default {
    name:'MessageForm',
    components:{
        BInputGroup, BFormInput, BInputGroupText,
    },
    props:{
        app_url:{
            required:true,
        }
    },
    data(){
        return{
            name:'',
            email:'',
            step:0,
            articleObj: null,
            isResult: false,
            searchQuery: '',
            is_submitting:false,
            style:-100,
            q_answers:[],
        }
    },
    created() {

    },
    methods:{
        showHide(){
          if(this.style== -100){
              this.style=6;
          }else{
              this.style= -100;
          }
        },
        hideMd(){
            this.style= -100;
        },
        allReset(){
            this.name='';
            this.email='';
            this.step=0;
            this.articleObj= [];
            this.isResult= false;
            this.searchQuery= '';
            this.q_answers=[];
        },
        async closeAll(){
            if(!this.is_submitting && this.q_answers.length){
                this.is_submitting=true;
                let data = new FormData();
                data.append('last_name',this.name);
                data.append('email',this.email);
                data.append('question_answers',JSON.stringify(this.q_answers));
                await axios.post(`${this.app_url}/customer/conversation/submit`,data).then((response)=>{
                    this.is_submitting=false;
                }).catch(()=>{
                    //
                });
            }
            this.hideMd();
            this.allReset();
        },
        searchClear(){
            this.articleObj= [];
            this.isResult= false;
            this.searchQuery= '';
        },
        removeSearchQuery() {
            this.searchQuery = '';
            this.isResult = false;
        },
        resultClick(q){
            this.q_answers.push({
                'question':q.question,
                'answer':q.answer,
                'type':q.type,
            });
            this.searchClear();
        },
        async submitQuestion(){
            if(!this.is_submitting && this.searchQuery){
                this.is_submitting=true;
                let data = new FormData();
                data.append('last_name',this.name);
                data.append('email',this.email);
                data.append('questions[]',this.searchQuery);
                await axios.post(`${this.app_url}/customer/question/submit`,data).then((response)=>{
                    this.is_submitting=false;
                    this.resultClick({question:this.searchQuery,answer:response.data.message, type:'custom'})
                    this.$toaster.info(response.data.message);
                }).catch(()=>{
                    this.is_submitting=false;
                    if (error.response.status === 422) {
                        Object.keys(error.response.data.errors).map((field) => {
                            this.$toaster.error(error.response.data.errors[field][0]);
                        });
                    } else this.$toaster.error(error.response.data.message);
                });
            }
        },
        async submitSearch() {
            var reqURL = this.app_url+`/customer/question/answer${this.searchQuery}`;
            if (this.searchQuery){
                await axios.get(reqURL).then((response)=>{
                    this.articleObj = response.data;
                    this.isResult = true;
                }).catch(()=>{
                    // console.log(error);
                });
            }
        }
    },
    watch:{
        articleObj(){
            this.isResult = this.articleObj.length;
        }
    }
}
</script>
<style>
a {
    color: #333;
}

.messenger-form .card {
    text-align: left;
    border-radius: 0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.13), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
    padding: 0 1.6rem;
    margin-bottom: 0.8rem;
    transition: .4s ease-out
}

.messenger-form .card:hover {
    background-color: lightgray;
    color: black;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.13), 0 3px 5px 0 rgba(0, 0, 0, 0.08);
}

.messenger-form .ctrl {
    margin-bottom: 1.6rem;
}

.messenger-form .removeInput {
    font-size: 36px;
    color: #ddd;
    cursor: pointer;
    top: 0;
    right: 0;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.searchForm {
    margin-bottom: 1px;
}
.messenger-form .searchForm {
    margin-bottom: 2.6rem;
    position: relative;
}

.messenger-form .text-body-1 {
    font-size: 15px;
    line-height: 20px;
    padding-top: 12px;
    margin-bottom: 1.4rem;
    letter-spacing: 0;
}

.messenger-form .text-headline {
    font-size: 18px;
    line-height: 22px;
    padding-top: 16px;
    margin-bottom: 2px;
    letter-spacing: 0;
}

/* vuejs transition */
.expand-transition {
    transition: all .5s ease;
    padding: 2px;
    min-height: 1500px;
    overflow: hidden;
    transition-delay: .5s;
}

.expand-enter, .expand-leave {
    height: 0;
    padding: 0 10px;
    opacity: 0;
}

/* Material Design code below */
.raised-button {
    display: inline-block;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: all 0.25s ease-out;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 0.85em 1em;
    margin: 0 1rem 1rem 0;
    font-size: 0.9rem;
    background: green;
    color: #FAFAFA;
}
.raised-button:hover, .raised-button:focus {
    background: green;
    color: #FFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.13), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
}

input:focus 		{ outline:none; }

/*[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"], textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    padding: 0.5rem;
    border: 0;
    margin: 0 0 1rem;
    font-family: inherit;
    font-size: 1rem;
    color: #9e9e9e;
    background-color: white;
    box-shadow: none;
    border-radius: 0;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
}*/
.message-content-heading{
    padding: 0;
    background: #adad4f17;
    position: relative;
    border-left: 6px solid #fed700;
}
.message-content-heading h4 {
    color: #fed700;
    padding-bottom: 10px;
    margin-left: 10px;
}
.message-content-heading h4::before {
    font-size: 18px;
    color: #fed700;
    left: 0;
    position: relative;
    content: "Q.";
    top: 0;
    margin-left: 10px;
    margin-right: 10px;
}
.message-description {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    border-left: 5px solid #ddd;
}
.message-description span {
    font-size: 18px;
    color: #ddd;
    left: 15px;
    position: relative;
    margin-right: 10px;
    /* width: 120px; */
}
.message-description p{
    margin-left: 25px;
    margin-top: 25px;
    font-size: 16px;
}
.message-content-area {
    border-bottom: 1px solid #ddd;
    padding: 0;
}
.message-content-area:last-child {
    border-bottom: none;
}
/*.scrollable-element {
    scrollbar-color: red yellow; !* red is for the thumb and yellow is for the track *!
}*/
.message-area::-webkit-scrollbar{width:6px;border-left:1px solid #E6ECF8;}
.message-area::-webkit-scrollbar-thumb{background-color: yellow;}
.message-area::-webkit-scrollbar-track {
    background: yellow;
}
.message-area::-webkit-scrollbar-thumb {
    background-color: #ff6c30 ;
    border-radius: 6px;
    border: 3px solid #ff6c30;
}
</style>
