//require("@babel/polyfill");
import store from './store';

import Vue from 'vue'

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

//Vue.component('create-purchase', require('./components/purchase/CreateComponent').default);

Vue.component('section-add-component', require('./components/section/SectionAddComponent').default);
Vue.component('section-add-main-component', require('./components/section/SectionAddMain').default);
Vue.component('section-update-main-component', require('./components/section/SectionUpdateMain').default);

Vue.component('slider-create', require('./components/slider/SliderCreate').default);
Vue.component('slider-update', require('./components/slider/SliderUpdate').default);

Vue.component('front-message', require('../front/components/Message').default);
Vue.component('front-service-purchase', require('../front/components/ServicePurchese').default);

const app = new Vue({
    store,
    el: '#app',
});

